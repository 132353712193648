.kofi-link img {
    height: 24px;
    margin-right: 10px;
}

@media only screen and (max-width:576px) {
    .kofi-link img {
        height: 18px;
        margin-right: 10px;
    }
}