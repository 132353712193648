footer {
    padding: 5rem 5.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #999;
}

.socials a {
    color: #999;
    margin-right: 16px;
    transition: color ease-in-out 200ms;
}

    .socials a:hover {
        color: #000;
    }

@media only screen and (max-width:576px) {
    footer {
        font-size: 12px;
        padding: 1.88rem;
    }

    .socials a {
        margin-right: 12px;
    }

    .socials svg {
        width: 18px;
        height: 18px;
    }
}