header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: rgba(255,255,255,0.96);
    backdrop-filter: blur(5px);
    font-size: 1.5rem;
    padding: 2.5rem 5.1rem;
    margin: 2.5rem 0;
    z-index: 2;
}
    /*    header.yoked + section > .content {
        height: 0;
        overflow: hidden;
    }
*/

    header.yoked svg.burger .l-1 {
        transform: rotate(45deg) translateY(4px);
    }

    header.yoked svg.burger .l-2 {
        transform: rotate(-45deg) translateY(-4px);
    }

.brand {
    font-size: 2.5rem;
    font-weight: 550;
}

header button {
    cursor: pointer;
    display: none;
    line-height: 0;
    background-color: transparent;
    padding: 0;
    border: none;
}

.burger {
    width: 24px;
    height: 24px;
    stroke-width: 2;
}

svg.burger line {
    transform-origin: center;
    transition: transform ease-in-out 300ms;
}

nav > a { /* Default state = inactive */
    display: inline-block;
    color: #999;
    text-decoration: none;
    line-height: 98%;
    margin-left: 2.25rem;
}

    nav > a.active { /* active state */
        color: #000;
        border-bottom: 3px solid black;
    }

.nav-shim {
    width: 0;
}

    .nav-shim aside {
        position: relative;
        height: 100%;
        width: 100vw;
        font-size: 1.5rem;
        background-color: #fff;
        z-index: 1;
        right: 0;
/*        padding: 0 5.1rem;*/
        transition: right ease-in-out 300ms;
    }

        .nav-shim aside > nav {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100vw;
            padding-right: 5rem;
            top: 13rem;
        }

            .nav-shim aside > nav > a {
                margin-left: 0;
                margin-bottom: 1.88rem;
            }

                .nav-shim aside > nav > a.active {
                    margin-bottom: calc(1.88rem - 3px);
                }

@media only screen and (max-width:767px) {
    header {
        align-items: center;
    }

        header.yoked + section > .nav-shim > aside {
            right: 100vw;
        }

        header nav {
            display: none;
        }

        header button {
            display: block;
        }
}

@media only screen and (max-width:576px) {
    header {
        font-size: 1.25rem;
        padding: 1.88rem;
        margin: 0;
    }

    .brand {
        font-size: 1.75rem;
        font-weight: 550;
    }

    .nav-shim aside > nav{
        font-size: 1.25rem;
        padding-right: 1.88rem;
        top: 8rem;
        /*padding: 0 1.88rem;*/
    }
}
