@font-face {
    font-family: 'Karla';
    font-style: normal;
    font-weight: 200 800;
    font-display: swap;
    src: url(./fonts/karla.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-family: 'Karla', sans-serif;
    font-weight: 450;
}

input, button {
    font-family: 'Karla', sans-serif;
}

svg {
    stroke-width: 1.5;
}

main#root {
    /*    display: flex;
    flex-direction: column;*/
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
}

    main#root > section {
        display: flex;
        overflow-x: hidden;
    }

    main#root > section > .content {
        flex: 1;
    }

a {
    text-decoration: none;
}
